// Imports
// ------------
import styled, { css } from 'styled-components';
import { breakup, Div } from '@tackl';

// Exports
// ------------
export const Jacket = styled(Div)(props => css`
    position: relative;
    z-index: 2;
    height: 48rem;
    background: ${props.theme.colors.brand.bc5o2};
    backdrop-filter: blur(1.2rem);
    margin: 3.6rem 0;

    /* left: -3.6rem; */
    /* width: calc(100% + 7.2rem); */

    ${breakup.large`
        height: calc(100vh - ${props.heightOffset}rem);
        max-height: 72rem;
        min-height: 57.6rem;
        margin: 0;

        width: 100%;
        left: auto;
    `}
`);

export const BackupForm = styled.form(props => css`
    position: absolute;
    z-index: 1;
    top: 0; left: 0; right: 0; bottom: 0;
    opacity: 0;
    pointer-events: none;
`);


export const MainForm = styled.form(props => css`
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;


    #conversational-form {
        background: transparent;

        &:before {
            display: none;
        }

        p {
            background: ${props.theme.colors.brand.bc5o2};
        }

        .conversational-form-inner {
            

            ${breakup.large`
                padding: 0 2.4rem;
            `}
        }

        cf-input { background: transparent; }

        .user {
            padding-right: 0;

            thumb {  display: none; }

            text {
                p {
                    background: linear-gradient(90deg,
                        ${props.theme.colors.brand.bc2} 0%,
                        ${props.theme.colors.brand.bc3} 100%
                    );
                }
            }
        }


        .inputWrapper {

            input {
                background: ${props.theme.colors.brand.bc5o2};
                color: ${props.theme.colors.brand.bc5};

                &:focus, &:hover, &:active {
                    color: ${props.theme.colors.brand.bc5};
                }

                &::placeholder {
                    color: ${props.theme.colors.brand.bc5o6};
                }
            }

            .cf-input-button {
                background: ${props.theme.colors.brand.bc5};

                .cf-icon-progress {
                    filter: invert(1);
                }
            }
        }
    }
`);