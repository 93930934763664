// Imports
// ------------
import React, { useRef, useEffect } from 'react';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

// Styles
// ------------
import { Jacket, BackupForm, MainForm } from './styles';

// Component
// ------------
const Form = ({ heightOffset, robot }) => {
    // NOTE • refs
    const form = useRef();
    const context = useRef();

    // NOTE • Breakpoints
    const bp = useBreakpoint();

    useEffect(() => {
        const { ConversationalForm } = require('conversational-form');

        const cf = new ConversationalForm({
            formEl: form.current,
            context: context.current,
            robotImage: robot,
            preventAutoFocus: bp.large ? true : false,
            showProgressBar: false,
            theme: 'light',
            userInterfaceOptions: {
                controlElementsInAnimationDelay: 250,
                robot: {
                    robotResponseTime: 200,
                    chainedResponseTime: 400,
                },
                user: {
                    showThinking: false,
                    showThumb: false,
                },
            },
            animationsEnabled: false,
            submitCallback: () => {
                let formData = cf.getFormData(true);

                fetch('https://formspree.io/f/xoqzqdqe', {
                    method: 'POST',
                    mode: 'no-cors',
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                    body: new URLSearchParams(formData).toString(),
                })
                    .then(() => {
                        cf.addRobotChatResponse(`I'll be in touch shortly {name}!`);
                    })
                    .catch((error) => {
                        cf.addRobotChatResponse('Something went wrong!');
                        cf.addRobotChatResponse('Try again?');
                    });
            },
        });
    }, []);

    let sharedFormProps = {
        name: 'contact-form',
        method: "POST",
        action: '',
    }

    return (
        <Jacket heightOffset={heightOffset}>
            <BackupForm ref={form} {...sharedFormProps}>
                <input type='hidden' name='form-name' value='contact' />

                {/* Honeypot spam filtering */}
                <input type="text" name="_gotcha" style={{ display: "none" }} />

                <fieldset cf-questions="Who do I have the pleasure of speaking with?">
                    <cf-robot-message cf-questions="Hey there 👋🏻" />
                    <label htmlFor='name'>What's your name?</label>
                    <input required type='text' name='name' id='name' />
                </fieldset>

                <fieldset cf-questions="What's your email?">
                    <cf-robot-message cf-questions="Hey {name}" />
                    <input
                        required
                        type='email'
                        name='email'
                        id='email'
                        cf-questions="What's your email?"
                        cf-error='Email address is required'
                    />
                    <label htmlFor='email'>Whats your email?</label>
                </fieldset>

                <fieldset cf-questions="...and the best number to call you on?">
                    <input required type='tel' name='telephone' id='telephone' />
                    <label htmlFor='telephone'>...and the best number to call you on?</label>
                </fieldset>

                <fieldset cf-questions='How can I help you and your business {name}?'>
                    <textarea name='description' id='description' />
                    <label htmlFor='description'>How can I help you and your business?</label>
                </fieldset>


                <button type='submit'>Submit</button>
            </BackupForm>

            <MainForm ref={context} {...sharedFormProps}>
                <input type='hidden' name='form-name' value='contact' />

                {/* Honeypot spam filtering */}
                <input type="text" name="_gotcha" style={{ display: "none" }} />
            </MainForm>

        </Jacket>
    );
}
export default Form;