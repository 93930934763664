// Imports
// ------------
import React from 'react';
import BackgroundVideo from './BackgroundVideo';
import Form from './Form';
import Icon from '@icons';
import { Row, Column } from '@waffl';
import { observer } from 'mobx-react-lite';
import { menuState } from '@states';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

// Styles
// ------------
import { Jacket, Content, TitleContainer, Title, Excerpt, SocialContainer, SocialList, SingleSocial, Social, Icons } from './styles';

// Component
// ------------

const SocialStuff = ({ fb, tw, li }) => (
    <SocialContainer>
        <SocialList>
            {fb && (
                <SingleSocial>
                    <Social target="_blank" rel="noopener noreferrer" href={fb}>
                        <Icons>
                            <Icon type="facebook" />
                            <Icon type="facebook" />
                        </Icons>
                    </Social>
                </SingleSocial>
            )}

            {tw && (
                <SingleSocial>
                    <Social target="_blank" rel="noopener noreferrer" href={tw}>
                        <Icons>
                            <Icon type="twitter" />
                            <Icon type="twitter" />
                        </Icons>
                    </Social>
                </SingleSocial>
            )}

            {li && (
                <SingleSocial>
                    <Social target="_blank" rel="noopener noreferrer" href={li}>
                        <Icons>
                            <Icon type="linkedin" />
                            <Icon type="linkedin" />
                        </Icons>
                    </Social>
                </SingleSocial>
            )}
        </SocialList>
    </SocialContainer>
);

const Contact = ({ title, subTitle, fb, tw, li, robot }) => {
    // NOTE • Breakpoints
    const bp = useBreakpoint();

    return (
        <Jacket specialPadding={menuState.heightSize} padBottom>
            <BackgroundVideo />

            <Row isExpanded isEqual>
                <Column small={12} large={4} pushLarge={1} mpad>
                    <Content stickyOffset={menuState.heightSize}>
                        <TitleContainer>
                            <Title>{title}</Title>
                            <Excerpt>{subTitle}</Excerpt>
                        </TitleContainer>

                        {bp.large ? (<SocialStuff fb={fb} tw={tw} li={li} />) : null}
                    </Content>
                </Column>

                <Column small={12} large={5} pushLarge={2} mpad>
                    <Form heightOffset={menuState.heightSize} robot={robot} />
                    {bp.large ? null : (<SocialStuff fb={fb} tw={tw} li={li} />)}
                </Column>
            </Row>
        </Jacket>
    );
}


export default observer(Contact);