// Imports
// ------------
import React, { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player/file';


import vid from '@images/cta.mp4';

// Styles
// ------------
import { Jacket } from './styles';

// Component
// ------------
const BackgroundVideo = () => {
    // NOTE • State
    const [ready, setReady] = useState(false);

    // NOTE • Functions
    const handleReady = () => {
        setReady(true);
    };


    return (
        <Jacket>
            <ReactPlayer
                url={vid}
                onReady={handleReady}
                playing={ready}
                loop
                muted
                playsinline
                width={`100%`}
                height={`100%`}
                controls={false}
            />
        </Jacket>
    );
}

export default BackgroundVideo;