// Imports
// ------------
import React from 'react';
import Contact from '@parts/Contact';
import Footer from '@parts/Footer';
import Seo from '@parts/Seo';
import { graphql } from 'gatsby';



// Component
// ------------
const ContactPage = ({ data }) => {
    const dato = data.page;

    return (
        <>
            <Seo
                title={data.seo.seoMeta.title}
                desc={data.seo.seoMeta.desc}
            />

            <Contact
                title={dato.heroTitle}
                subTitle={dato.heroSubtext}
                fb={data.global.fb}
                tw={data.global.tw}
                li={data.global.li}
                robot={data.page.robot.pic.url}
            />

            <Footer />
        </>
    );
}

export default ContactPage;

// GraphQL
// ------------
export const query = graphql`
	query ContactPageQuery {
		# NOTE • SEO
		seo: datoCmsContactPage {
			seoMeta {
				title
				desc: description
			}
		}
        # NOTE • Page Specific
        page: datoCmsContactPage {
            # Hero
            heroTitle
            heroSubtext
            robot: formRobot {
                pic: authorPicture {
                    url(imgixParams: {
                        w: "72"
                        h: "72"
                    })
                }
            }
        }

        global: datoCmsCallToAction {
            fb: facebookUrl
            tw: twitterUrl
            li: linkedinUrl
        }
	}
`
