// Imports
// ------------
import styled, { css } from 'styled-components';
import { breakup, Div } from '@tackl';

// Exports
// ------------
export const Jacket = styled(Div)(props => css`
    position: fixed;
    top: 0; left: 0; right: 0; bottom: 0;
    z-index: 1;

    &:after {
        content: "";
        position: absolute;
        top: 0; left: 0; right: 0; bottom: 0;
        background: linear-gradient(180deg,
            ${props.theme.colors.brand.bc1trans0} 50%,
            ${props.theme.colors.brand.bc1} 100%
        );
    }

    video {
        object-fit: cover;
    }
`);
